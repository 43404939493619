$primary: #009604; 
$secondary:#FFA941;
$dark: #000000;
$light: #777777;
$success: #2aaf71;
$warning: #D7DF23;
$extralight: #666666;
$body-color: #000000;
$info-light:#E7EAF3;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "warning":    $warning,
  "light":      $light,
  "dark":       $dark,
  "danger": #dc3545,
  "success": $success,
  "yellow": #F4B81C,
  'info': #355CEC,
  "info-light": $info-light,
);

$container-max-widths: ( xxl: 1350px );
$min-contrast-ratio:   1.5 !default;
$headings-line-height: 1.5;
$enable-negative-margins: true; 
$breadcrumb-item-padding-x:         1rem;
$breadcrumb-margin-bottom:          1.875rem;
$breadcrumb-divider-color:          $dark;
$btn-padding-y-lg:            0.75rem;
$btn-padding-x-lg:            2rem;
$btn-font-size-lg:            1rem;


$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * .75, //12
  7: $spacer * .875, //14
  8: $spacer * 1.25, //20
  9: $spacer * 1.875, //30
) !default;

$bordercolor : #ECECEC;
$darkblue : #355CEC;
$lightblue : #D9DFF2;
$blurborder : #99ABEE;


$tooltip-font-size:0.75rem;
$tooltip-padding-y:0.5rem;
$tooltip-bg:#355CEC;
$tooltip-arrow-color:#355CEC;
$tooltip-opacity:1;

$font-family-sans-serif: 'Poppins', sans-serif;
$border-radius:.375rem;
$border-radius-lg:.625rem; 

$input-btn-padding-y:         .65rem !default;
$input-btn-padding-x:         1rem !default;

$btn-padding-y:               .5rem !default;
$btn-border-radius: 0;
$input-placeholder-color: red;




$box-shadow-sm: 0px 1px 4px rgba(0, 0, 0, 0.1);
:root {
--toastify-color-light: #E7EAF3;
--toastify-toast-min-height: 60px;
--toastify-text-color-light: #777777;
}