// @import url('https://fonts.cdnfonts.com/css/univers-lt-std?styles=21271,21272,21273,21274,21293,21294,21295,21296');
// @import url('https://fonts.cdnfonts.com/css/univers-lt-std?styles=21293,21294,21295,21296');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import "variables";
@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: 'Univers LT Std';
    src: url('../fonts/universltstd-xblackexobl-webfont.woff2') format('woff2'),
         url('../fonts/universltstd-xblackexobl-webfont.woff') format('woff'),
         url('../fonts/universltstd-xblackexobl-webfont.ttf') format('truetype'),
         url('../fonts/universltstd-xblackexobl-webfont.svg#univers_lt_std93XBlkExOb') format('svg');
    font-weight: 950;
    font-style: normal;

}

h1 { font-family: 'Univers LT Std', sans-serif; font-weight: 950; font-style: italic; font-size: 38px; line-height: 1.215; text-transform: uppercase; color:#000; margin-bottom: 40px; }

html, body { min-height: 100vh;}
body { font-family: 'Open Sans', sans-serif; font-size: 16px; line-height: 1.2; background: #F6F6F6 url("../images/bg.jpg") no-repeat center center; background-size: cover; color: #333333; }
body.homepage { background: url("../images/colorbg.jpg") no-repeat center center; background-size: cover; }
body.listpage .header, body.homepage .header { background: #ffffff;}
body.listpage { background: #f6f6f6; }

.btn-dark, .btn-warning {
	--bs-btn-border-radius : 0;
	--bs-btn-padding-y: 0.975rem;
	--bs-btn-font-family: 'Univers LT Std', sans-serif;
	--bs-btn-font-weight: 950;
	--bs-btn-font-size: 18px;
	--bs-btn-hover-bg:#D7DF23;
	--bs-btn-hover-border-color:#D7DF23; 
	text-transform: uppercase;
	font-style: italic;
  
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance: textfield;  }

#root { min-height: 100vh; display: flex; }
.wrapper { display: flex; flex-direction: column; width: 100%; justify-content: space-between; }
.header .logo, .header .logocolor { padding: 33px 53px;}
.logocolor { background: #1F1F1F;}

.loginbox { min-width: 670px; max-width: 670px; margin: 0 auto; text-align: center; background: #fff; box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16); padding: 68px 110px 83px; margin-top: -81px; }
.loginbox p { font-size: 18px; line-height: 1.8; margin-bottom: 38px; }
.loginbox p:last-child { margin-bottom: 0; }
.loginbox p strong { font-style: italic;}
.contactinfo .form-control,
.loginbox .form-control { background: #FFFFFF; border: 1px solid #DDDDDD; font-size: 18px; color: #9F9F9F; line-height: 1; font-weight: 700; padding: 16px 30px; border-radius: 0; }
.loginbox button { position: relative;}
.loginbox .sendicon { position: absolute; right: 28px; top: 16px;}
button:hover .sendicon path { stroke: #fff;}
.loginbox .checkicon { margin-bottom: 26px;}


.footer { background: #1F1F1F; font-family: 'Univers LT Std'; font-style: italic; font-weight: 950; text-transform: uppercase; color: #FFFFFF; padding: 22px 55px; }
.footer span { display: block; font-family: 'Open Sans'; font-size: 16px; line-height: 16px; font-style: normal; font-weight: 700; color: #929292; }
.footer .poweredby { font-family: 'Open Sans', sans-serif; font-size: 10px; text-align: center; letter-spacing: 0.3em; font-weight: 400; font-style: normal;}
.footer .poweredby svg { margin-top: 10px; width: 140px; }
.footer .poweredby svg path { fill: #fff;  }
.form-control:focus { box-shadow: none;}
.btn:focus-visible { border-color: transparent; box-shadow: none; }

.slidedown-enter { opacity: 0; transform: translateY(-50px); }
.slidedown-enter-active { opacity: 1; transition: all 0.2s ease-in; transform: translateY(0px); }
.slidedown-exit { opacity: 1; transform: translateY(0px); }
.slidedown-exit-active { opacity: 0; transition: all 0.2s ease-in; transform: translateY(-50px); }

.searchbox { width: 100%; max-width: 960px; }
.searchbox .form-control { background: #FFFFFF; border: 1px solid #DDDDDD; border-radius: 0; padding: 15px 14px; font-weight: 700; font-size: 14px; line-height: 28px; color: #B8B8B8; border-left: 0; text-transform: uppercase; }
.homesearchbar.searchbox .form-control { font-size: 20px; padding: 20px 14px; }
.searchbox .input-group-text { padding: 0 15px; background: transparent; border-radius: 0; border-right: 0; position: relative; background: #fff; }
.homesearchbar.searchbox .input-group-text { padding: 0 20px; }
.homesearchbar.searchbox .input-group-text svg { width: 25px; height: 25px; }
.searchbox .input-group-text:after { content: ''; width: 2px; background: #dddddd; position: absolute; right: 0; top: 16px; bottom: 16px;}
.minicart { margin-right: 43px;}
.minicart div { font-family: 'Univers LT Std'; font-style: italic; font-weight: 950; font-size: 20px; line-height: 17px; }
.minicart span { display: block; }
.minicart .carticon { position: relative; width: 38px; height: 38px; margin-right: 10px; }
.minicart .carticon span { font-family: 'Open Sans', sans-serif; font-size: 12px; font-weight: 700; font-style: normal; display: flex; justify-content: center; align-items: center; position: absolute; top: -10px; right: -5px; color: #ffffff; z-index: 2; width: 18px; height: 20px; line-height: 20px; background: url('../images/carticonbg.svg') no-repeat center center; background-size: 18px; }

// .minicart .carticon span::before { content: "\2B22"; position: absolute; color: #E95D15; font-size: 21px; z-index: -1; left: -1; top: -2px; }
.minicart button { background: transparent; border:0; text-align: left; }

.homebox { align-self: center; color: #fff; text-align: center; width: 100%; max-width: 950px; padding: 30px 15px; }
.homebox h2 { font-weight: 700; font-size: 30px; line-height: 2; margin-bottom: 11px; }
.homebox h1 { color: #fff; font-size: 60px; max-width: 90%; margin: 0 auto 26px; line-height: 1; }
.homebox p { font-size: 20px; line-height: 3; margin-bottom: 0; }
.form-control::-webkit-input-placeholder { color: #bbbbbb; }  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #bbbbbb; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #bbbbbb; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #bbbbbb; }  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: #bbbbbb; }  /* Microsoft Edge */

.homeboxpart { background: #ffffff; flex: 1 0 0; }


// search result
.listwrapper { min-height: 80vh; display: flex; width: 100%; flex-wrap: wrap; } 
.filterbox { flex: 0 0 280px; max-width: 280px; overflow: hidden; }
.searchresult { flex: 1; padding: 38px; min-width: 0; }

.searchresult .col { padding: 0 5px; margin-bottom: 10px; }
.searchresult .row { margin: 0 -5px; }
.listbox { background: #ffffff; padding: 25px 14px; transition: all 0.2s ease }
// .listbox:hover { box-shadow: 0px 8px 42px rgba(0, 0, 0, 0.09); }
.listbox:hover { box-shadow:0 2px 20px rgba(0,0,0,.2); transform: scale(1.02); }
.listbox .imgbox { margin-bottom: 40px;}
.listbox .desc { color: #333333; padding: 0 11px; line-height: 1.1;}
.listbox .small { font-size: 12px; line-height: 1.4;}
.listbox .slick-slide > div > div,
.listbox .slick-slide > div > div span { height: 214px;}
.listbox .slick-slide > div > div span.lazy-load-image-background { width: 100%;}
.listbox img { width: 100%; height: 100%; object-fit: contain; cursor: pointer; }
.listbox img.placeimg { height: 188px;}
.listbox .slick-dots li { margin: 0; }
.listbox .slick-dots li button { width: 10px; height: 10px; background: #fff; border: 1px solid #BCBCBC; border-radius: 100%; }
.listbox .slick-dots li.slick-active button { background: #BCBCBC;}
.listbox .slick-dots li button::before { content: none; }

// sidebar
.sidebar { padding: 20px 18px; position: relative; }
.sidebar .catmenu button { background: transparent; border: 0; font-family: 'Univers LT Std', sans-serif; color: #1F1F1F; font-size: 18px; text-transform: uppercase; display: flex; align-items: center; }
.sidebar .catmenu button span { width: 58px; height: 58px; background: #1F1F1F; display:flex; justify-content: center; align-items: center; margin-right: 16px; }
.sidebar .itemlist { margin: 25px 0 0 26px; padding: 30px 18px 0; border-top: 1px solid #D4D4D4; }
.sidebar .itemlist h3 { font-family: 'Univers LT Std'; font-size: 18px; line-height: 1.225; margin-bottom: 10px; text-transform: uppercase; }
.sidebar .itemlist h4 { font-size: 12px; color: #1F1F1F; line-height: 1.325; }
.sidebar .itemlist ul, .sidebar .itemlist li { margin: 0; padding: 0; list-style-type: none; }
.sidebar .itemlist li { padding: 8px 0;}
.customcheckbox label { color: #1F1F1F; font-size: 14px; text-transform: uppercase; line-height: 22px; cursor: pointer; }
.customcheckbox label span { display: block; position: relative; padding-left: 32px; }
.customcheckbox label span::before { content: ''; width: 22px; height: 22px; background: #ffffff; left: 0; top: 0; border: 1px solid #CCC; position: absolute; }
.customcheckbox label span::after { content: ''; width: 12px; height: 5px; left: 5px; top: 7px; border-left: 3px solid #fff; border-bottom: 3px solid #fff; position: absolute; transform: rotate(-45deg); opacity: 0; }
.customcheckbox label input:checked + span::before { background: #000; }
.customcheckbox label input:checked + span::after { opacity: 1; }
.customcheckbox label input[type="checkbox"]{ position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0,0,0,0); white-space: nowrap; border-width: 0; }

.backicon { font-size: 14px; font-family: 'Univers LT Std', sans-serif; color: #1F1F1F; line-height: 58px; text-align: center; }
.backicon button{ background: transparent; border:0; }
.backicon button svg { margin-left: 24px;}
.sidebar .catlist { position: absolute; left: 0; top: 24px; width: 100%; }
.sidebar .catlist .itemlist li { padding: 10px 0;}
.sidebar .catlist .itemlist li label,
.sidebar .catlist .itemlist li a { color: #1F1F1F; font-size: 15px; text-decoration: none; text-transform: uppercase; cursor: pointer; }

// .sidebar .itemtypescover,
// .sidebar .itemtypes, .sidebar .catlist {
// 	display: flex;
// 	flex-direction: column;
// 	height: calc(100vh - 140px);
// }
// .sidebar .itemlist.typelist {
// 	// max-height: 50%;
// 	max-height: 200px;
// 	overflow: hidden;
// }

// .sidebar .itemlist.taglist {
// 	align-self: stretch;
// 	flex: 1;
// 	overflow: hidden;
// }
// .sidebar .catlist .itemlist { height: 87%;}
// .sidebar .itemlist > div, .sidebar .itemlist > ul, .sidebar .catlist > ul { overflow-y: auto; height: 88%; }
.sidebar .itemlist.taglist .customcheckbox label span { font-size: 13px; text-transform: capitalize; }
// // ::-webkit-scrollbar { display: none;}
// /* width */
// ::-webkit-scrollbar { width: 2px; }
// /* Track */
// ::-webkit-scrollbar-track { background: #f1f1f1; }
// /* Handle */
// ::-webkit-scrollbar-thumb { background: #888; }
// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover { background: #555; }

// .sidebar .itemlist > div, .sidebar .itemlist > ul, .sidebar .catlist > ul { -ms-overflow-style: none; scrollbar-width: thin;}

.slideLeft-enter { transform: translateX(-100%); }
.slideLeft-enter-active { transition: all 0.2s ease-in; transform: translateX(0); }
.slideLeft-exit { transform: translateX(0px); }
.slideLeft-exit-active { transition: all 0.2s ease-in; transform: translateX(-100%); }

.slideRight-enter { transform: translateX(100%); }
.slideRight-enter-active { transition: all 0.2s ease-in; transform: translateX(0); }
.slideRight-exit { transform: translateX(0%); }
.slideRight-exit-active { transition: all 0.2s ease-in; transform: translateX(110%); }

.home-sidebar.sidebar {
	position: absolute;
	top: 100px;
	width: 280px;
	bottom: 100px;
	overflow: hidden;
	overflow-y: auto;
}
.home-sidebar.sidebar .itemtypes .itemlist {
	display: none;
}
.home-sidebar.sidebar .catlist {
	background: #ffffff;
	top: 0;
	bottom: 0;
}
.home-sidebar.sidebar .backicon { margin-top: 20px;}
.home-sidebar.sidebar .catmenu button span {
	background: #616161;
}
.home-sidebar.sidebar .catmenu button { color: #616161;}

// .searchresult,
// .itemtypes {
//     height: 80vh;
//     overflow: hidden;
//     overflow-y: auto;
// }


// offcanvas
.addtocartmodal.offcanvas.offcanvas-end { background: #1F1F1F; width: 100%; max-width: 640px; padding: 40px; }
.addtocartmodal .closemodal { position: absolute; background: #919191; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; cursor: pointer; z-index: 2; margin: 10px; }
.addtocartmodal .offcanvas-body { padding: 0; color: #fff; }
.addtocartmodal .imgbox { background: #ffffff; display: flex; align-items: center; justify-content: center; height: 440px; position: relative; }
.addtocartmodal .imgbox img { width: 100%; height: 100%; object-fit: contain;}
.addtocartmodal .imgbox::after { content: ''; position: absolute; left: 0; top: 0; right: 0; bottom: 0; background: url(../images/watermark.png) no-repeat center center; background-size: cover; opacity: 0.8; }
.addtocartmodal .title span { font-size: 15px;}
.addtocartmodal .title h2 { font-size: 18px; font-weight: 700; margin-bottom: 0; }
.addtocartmodal .desc { font-size: 15px; flex: 1; font-weight: 600; line-height: 1.35;}
.addtocartmodal .desc span { flex: 0 0 75px; max-width: 75px; font-weight: 400; }
.addtocartmodal .offcanvas-footer { font-family: 'Univers LT Std'; text-transform: uppercase; color: #FFFFFF; font-size: 24px; text-align: center;  }
.addtocartmodal .offcanvas-footer svg { width: 28px;} 
.addtocartmodal .offcanvas-footer span { display: block; font-family: 'Open Sans'; font-size: 25px; line-height: 34px; font-style: normal; font-weight: 700; color: #929292; line-height: 1; }
.offcanvas-backdrop.show.newbackdrop { opacity: 0; }

.cartmodal.offcanvas.offcanvas-end { width: 100%; max-width: 500px; }
.cartmodal .offcanvas-header { background: #333333; padding: 42px 29px 33px; }
.cartmodal .offcanvas-title { font-family: 'Univers LT Std'; font-size: 26px; color: #ffffff; text-transform: uppercase; }
.cartmodal .closemodal { width: 40px; height: 40px; cursor: pointer; display: flex; align-items: center; justify-content: center; }
.cartmodal .offcanvas-body { padding: 36px 24px;}
.cartmodal .itemcount {
	font-family: Univers LT Std;
	font-size: 22px;
	text-transform: uppercase;
	border-bottom: 1px solid #ddd;
	padding-bottom: 20px;
}
.cartmodal .cartitembox {
	padding: 24px 4px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
}
.cartitembox .imgbox {
	flex: 0 0 126px;
	max-width: 126px;
	height: 126px;
}
.cartitembox .imgbox img { width: 100%; height: 100%; object-fit: contain;}
.cartitembox .desc {
	padding: 0 24px;
}
.cartitembox .desc small {
	font-size: 12px;
	line-height: 1.35;
	margin-bottom: 10px;
	display:block ;
}
.cartitembox .desc small:last-child { margin-bottom: 0; }
.cartitembox .desc h4 {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 10px;
	line-height: 1.4;
}
.contactinfo h2 { font-family: 'Univers LT Std'; font-size: 22px; line-height: 1; margin-bottom: 24px; text-transform: uppercase; margin-top: 36px; }
.contactinfo .form-control { text-transform: none;}
.commonloader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
	z-index: 11;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 50px white inset !important;
}

// 04/04/2023
.itemtypes .catmenu > button:first-child { display: none !important;}
.sidebar .itemlist { margin: 0px 0 0 26px; padding: 10px 18px 0; border-top: 0px solid #D4D4D4; }
.sidebar .itemlist + .itemlist { margin-top: 20px;}
.addtocartmodal .title span { margin-bottom: 8px; display: block;}
.addtocartmodal .title .h2, .addtocartmodal .title h2 { line-height: 22px;}
.addtocartmodal .offcanvas-body { -ms-overflow-style: none !important; scrollbar-width: thin !important;}

.addtocartmodal .offcanvas-body::-webkit-scrollbar { width: 0px; }
// /* Track */
.addtocartmodal .offcanvas-body::-webkit-scrollbar-track { background: #f1f1f1; }
// /* Handle */
.addtocartmodal .offcanvas-body::-webkit-scrollbar-thumb { background: #888; }
// /* Handle on hover */
.addtocartmodal .offcanvas-body::-webkit-scrollbar-thumb:hover { background: #555; }
.addtocartmodal .offcanvas-body { -ms-overflow-style: none; scrollbar-width: none;}
.sharedropcover button:hover img { filter: invert(1); }
.sharedropcover .btn-outline-dark { border:2px solid #000; border-radius: 0; }
.sharedrop .btn-outline-dark { background: #000; color: #fff; border-color: #000; border-width: 1px; }
.sharedrop .btn-outline-dark:hover { background: #d7df23; color: #fff; border-color: #d7df23; }
.sharedrop { position: absolute; right: 12px; top: 100%; background: #f6f6f6; border:1px solid #c9c9c9; padding: 16px; z-index: 9; width: 390px; }
.sharedrop .form-control { font-size: 12px; }

.addcontentcover { max-width: 900px; width: 96%; margin: 0 auto 30px; }
.addcontentcover textarea.form-control { height: 280px; }
.addcontentcover button { position: relative;}
.addcontentcover .sendicon { position: absolute; right: 28px; top: 16px;}
@media only screen and (max-width:767px) {
	h1 { font-size: 28px; margin-bottom: 20px;}
	.header .logo, .header .logocolor { padding: 33px 20px; max-width: 180px; }
	.header .logo svg, .header .logocolor svg { max-width: 100%; }
	.loginbox { padding: 30px 20px; min-width: auto; width: 96%; }
	.footer { padding: 22px 20px; flex-direction: column; text-align: center; }
	.footer .poweredby { margin-top: 20px; }
	.homebox h1 { color: #fff; font-size: 30px; }
	.homebox h2, .homebox .h2 { font-size: 20px; }
	.minicart { margin-right: 15px; font-size: 16px;}
	.filterbox { flex: 0 0 100%; max-width: 100%; }
	.searchresult { flex: 0 0 100%; padding: 0 0 38px; }
	.searchresult .col { flex: 0 0 100%;}
	
	.addtocartmodal.offcanvas.offcanvas-end { padding: 20px; }
	.addtocartmodal .desc { flex: 0 0 100%; }
	.cartmodal .offcanvas-header { padding: 40px 20px 30px; }
	.cartmodal .offcanvas-title { font-size: 22px; }
	.cartmodal .offcanvas-body { padding: 36px 15px; }
	.cartitembox .imgbox { flex: 0 0 80px; 	max-width: 80px; }
	.cartitembox .desc { padding: 0 15px; }
	.contactinfo h2, .contactinfo .h2, .cartmodal .itemcount { font-size: 18px; }
	.btn-dark, .btn-warning { --bs-btn-font-size: 16px;}
	.sidebar { padding: 20px 12px;	}
	.sidebar .itemtypes {
		position: fixed;
		top: 120px;
		bottom: 0;
		background: #fff;
		left: 12px;
		right: 0;
		z-index: 2;
		overflow-y: auto;
	}
	.sidebar .catlist {
		position: fixed;
		left: 0;
		top: 120px;
		width: 100%;
		background: #fff;
		bottom: 0;
		z-index: 9;
	}
	.sidebar .catmenu button.filterclose { position: absolute; right: 0;}
	.sidebar .catmenu button.filterclose span { background: transparent;}
	.sidebar .catmenu button.filterclose span svg path { stroke: #000; }
	.sidebar .itemlist {
		margin: 20px 0 0 26px;
	}
	.header { flex-wrap: wrap; }
	.minicart { 
		position: absolute; right: 0; top: 30px;
	}
	.topsearch.searchbox { background: #f6f6f6; padding: 16px 12px 12px !important; }
	.sharedropcover > .btn-outline-dark { position: absolute; right: 6px; min-width: 100px; top: -90px; }
	.sharedropcover .sharedrop {
		width: 400px;
		top: -40px;
	}
}